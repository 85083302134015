<template>
  <div class="fate-page">
    <div class="fate-body">
      <div class="fate-content">
        <div class="list-scroll">
          <ul>
            <li v-for="(item, index) in fleetingNav"
              :key="index"
              :class="{ active: item.isActive }"
              @click="fleetClick(item)">
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="fleeting-body">
          <ul :class="addClass">
            <li class="fleeting-list"
              v-for="(item, index) in detailsList"
              :key="index">
              <a :href="item.jumpUrl"
                @click="jumpUrlAction(item)">
                <div class="fleeting-image">
                  <img :src="item.imgUrl" alt="">
                </div>
                <div class="fleeting-text">
                  <p class="bold">{{ item.title1 }}</p>
                  <p class="normal">{{ item.title2 }}</p>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fateData from './utils/style.json';
import { setTimeout } from 'timers';

export default {
  name: 'Fate',
  data() {
    return {
      detailsList: [],
      addClass: '',
    };
  },
  computed: {
    hotList() {
      return fateData.hotRecommend;
    },
    fleetingData() {
      return fateData.fleetingYears;
    },
    fleetingNav() {
      const { nav } = this.fleetingData;
      this.$set(nav[0], 'isActive', true);
      return nav;
    },
    fleetingDetails() {
      return this.fleetingData.details;
    },
    imgList() {
      return fateData.swipperList;
    },
  },
  mounted() {
    document.title = '流年运势';
    this.detailsList = this.fleetingDetails;
  },
  methods: {
    fleetClick(data) {
      const { fleetingDetails } = this;
      let { id } = data;
      let list = [];
      this.fleetingNav.forEach((item) => {
        this.$set(item, 'isActive', false);
      });
      this.addClass = 'fleet-left-tranck';
      this.detailsList = [];
      this.$set(data, 'isActive', true);
      setTimeout(() => {
        if (id) {
          list = fleetingDetails.filter((item) => item.id === id);
        } else {
          list = fleetingDetails;
        }
        this.detailsList = list;
      }, 100);
      setTimeout(() => {
        this.addClass = '';
      }, 100);
    },
    jumpUrlAction(data) {
      window._czc && window._czc.push(['_trackEvent', data.name, '点击', '流年运势']);
    },
  },
};
</script>
<style lang="scss" scoped>
  .fate-page {
    width: 100%;
    min-height: 100%;
    background-image: url('//download-cos.yofish.com/youyu-gongjushiyebu/20220408143252381-67.png');
    background-repeat: repeat;
    font-family: PingFang-SC-Medium, PingFang-SC;
    .fate-body {
      margin: 0 26px;
      .fate-content {
        padding-top: 53px;
        // padding-bottom: 30px;
        // &:last-child {
        //   margin-top: 56px;
        // }
        .fate-desc {
          font-size: 34px;
          font-weight: 800;
          color: #FFFFFF;
          line-height: 45px;
          text-shadow: 2px 2px 0px #6F46FF;
        }
        .recommend {
          width: 100%;
          height: 275px;
          background: rgba(#936AFF, .85);
          overflow: hidden;
          border-radius: 12px;
          backdrop-filter: blur(5px);
          margin-top: 36px;
          position: relative;
          .recommend-scroll {
            overflow: auto;
            position: relative;
            z-index: 40;
            &::-webkit-scrollbar {
              display:none; /* ChromeSafari */
            }
          }
          .recomd-top {
            position: absolute;
            width: 172px;
            right: 0;
            bottom: 0;
            z-index: 2;
            img {
              width: 100%;
            }
          }
        }
        .recommend-card {
          display: flex;
          margin: 29px 26px 0 26px;
          .fate-list {
            width: 220px;
            margin-right: 21px;
            flex: 0 0 auto;
            position: relative;
            z-index: 1;
            // display: inline-block;
            &:last-child {
              padding-right: 26px;
            }
            .image {
              height: 160px;
              border-radius: 8px;
              // background-color: #fff;
              margin-bottom: 26px;
              border: 1px solid #AF91FF;
              img {
                width: 100%;
                border-radius: 8px;
              }
            }
            .text {
              font-size: 26px;
              font-weight: 550;
              color: #FFFFFF;
              line-height: 37px;
              // margin-left: 3px;
              text-align: center;
            }
          }
        }
      }
    }
    /deep/.swiper-container {
      // overflow: visible !important;
      // width: 80%;
      // height: 300px;
    }
  }
  .fate-content {
    width: 100%;
    overflow: hidden;
    .list-scroll {
      overflow: auto;
      &::-webkit-scrollbar {
        display: none; /* ChromeSafari */
      }
      ul {
        display: flex;
        li {
          // margin-right: 37px;
          font-size: 32px;
          font-weight: 400;
          color: #333333;
          line-height: 60px;
          flex: 0 0 auto;
          position: relative;
          height: 60px;
          width: 162px;
          text-align: center;
          &.active {
            font-size: 34px;
            font-weight: 500;
            // line-height: 45px;
            color: #A1731E;
            background: linear-gradient(90deg, #FFD176 0%, #FFE5B1 100%);
            border-radius: 40px;
          }
        }
      }
    }
  }
  .fleeting-body {
    margin-top: 38px;
    ul {
      display: flex;
      flex-wrap: wrap;
      transition: all .25s;
      &.fleet-left-tranck {
        transform: translateX(100%);
      }
      &.fleet-right-tranck {
        transform: translateX(100%);
      }
      li.fleeting-list {
        flex: 0 0 auto;
        width: 334px;
        background: #FFFFFF;
        border-radius: 12px;
        margin-right: 30px;
        margin-bottom: 20px;
        &:nth-child(even) {
          margin-right: auto;
        }
        .fleeting-image {
          width: 100%;
          height: 306px;
          margin-bottom: 20px;
          img {
            width: 100%;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
          }
        }
        .fleeting-text {
          width: 297px;
          margin: auto;
          .bold, .normal {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space:nowrap;
          }
          .bold {
            font-size: 30px;
            font-weight: 550;
            color: #333333;
            line-height: 42px;
          }
          .normal {
            font-size: 26px;
            font-weight: 400;
            color: #999999;
            line-height: 33px;
            margin-top: 12px;
            padding-bottom: 28px;
          }
        }
      }
    }
  }
  a {
    text-decoration: none;
  }
</style>
<style>
  /* .fate-header .swiper-slide {
    transition: transform .3s;
  }
  .fate-header .swiper-slide:not(.swiper-slide-active) {
    transform: scale(.9);
  } */
</style>
